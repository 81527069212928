<template>
  <div>
    <Hero
      title="Zoom Integration"
      description="Documentation"
      :palette="['#2D8CFF', '#2D8CFF']"
      :angle="120"
      :dark="true"
      :transparency="true"
      :gradientAnimation="true"
      :fillHeight="false"
      animationName="fade-in"
    />

    <v-container>
      <div class="my-9">
        <div class="text-h3 mb-6" data-aos="fade-in">
          How to use Zoom with Abardo
        </div>

        <div class="text-body-1 mb-6" data-aos="fade-in">
          Integrate your
          <a href="https://zoom.us" target="_blank">Zoom</a> account to
          seamlessly host all your
          <router-link to="/solutions/virtual">virtual events</router-link> from
          your own private or public branded company landing page, allowing your
          colleagues or attendees to see all upcoming events.
        </div>

        <div class="text-h4 my-6" data-aos="fade-in">1. Sign in with Zoom</div>

        <div class="text-body-1 mb-6" data-aos="fade-in">
          Simply
          <a href="https://app.abardo.com/sign-in" target="_blank">sign in</a>
          to Abardo with your school/work Zoom account or your organisations
          email address.
        </div>

        <div class="text-h4 my-6" data-aos="fade-in">
          2. Create a virtual event on Abardo
        </div>

        <div class="text-body-1 mb-9" data-aos="fade-in">
          From your company landing page, simply click 'New event' to start
          building your first virtual event page with Zoom.
        </div>

        <v-img :src="image1" data-aos="fade-in"></v-img>

        <div class="text-h4 my-6" data-aos="fade-in">3. Host with Zoom</div>

        <div class="text-body-1 mb-9" data-aos="fade-in">
          Select 'Zoom meeting' to host your meeting with Zoom. You can host up
          to 500 participants, dependant on your existing Zoom
          <a href="https://zoom.us/pricing" target="_blank">pricing plan</a>.
        </div>

        <v-img :src="image2" data-aos="fade-in"></v-img>

        <div class="text-h4 my-6" data-aos="fade-in">4. Invite speakers</div>

        <div class="text-body-1 mb-9" data-aos="fade-in">
          Invite your colleagues to speak at your event with a single click or
          create a profile for external speakers.
        </div>

        <v-img :src="image3" data-aos="fade-in"></v-img>

        <div class="text-h4 my-6" data-aos="fade-in">
          5. Build professional event agendas
        </div>

        <div class="text-body-1 mb-9" data-aos="fade-in">
          Create sessions, add speakers and files in a seamless and interactive
          way.
        </div>

        <v-img :src="image4" data-aos="fade-in"></v-img>

        <div class="text-h4 my-6" data-aos="fade-in">6. Launch your event</div>

        <div class="text-body-1 mb-9" data-aos="fade-in">
          Choose your audience and launch with a single click.
        </div>

        <v-img :src="image6" data-aos="fade-in"></v-img>

        <div class="text-h4 my-6" data-aos="fade-in">
          7. Calendar invitations
        </div>

        <div class="text-body-1 mb-9" data-aos="fade-in">
          Automatically send calendar invitations and reminders to your speakers
          and attendees from your company, with everything they need to join on
          the day.
        </div>

        <v-img :src="image7" data-aos="fade-in"></v-img>

        <div class="text-h4 my-6" data-aos="fade-in">8. Promote your event</div>

        <div class="text-body-1 mb-b" data-aos="fade-in">
          Share your event globally across social media platforms and allow
          attendees to register for your event.
        </div>

        <v-img :src="image8" data-aos="fade-in"></v-img>

        <div class="text-h4 my-6" data-aos="fade-in">Disconnect Zoom</div>

        <div class="text-body-1 mb-9" data-aos="fade-in">
          You can disconnect Zoom from Abardo at any-point by simply clicking
          your picture in the top right hand corner of the screen. Click
          'Disconnect Zoom' and your Zoom account details will be removed from
          Abardo.
        </div>

        <v-img :src="image9" data-aos="fade-in"></v-img>
      </div>
    </v-container>
  </div>
</template>

<script>
import Hero from "@/components/shared/Hero";

export default {
  components: { Hero },
  data: () => ({
    image0: require("@/assets/images/docs/integration/zoom/sign-in.png"),
    image1: require("@/assets/images/docs/integration/zoom/new-event.png"),
    image2: require("@/assets/images/docs/integration/zoom/new-event-type.png"),
    image3: require("@/assets/images/docs/integration/zoom/speakers.png"),
    image4: require("@/assets/images/docs/integration/zoom/agenda.png"),
    image5: require("@/assets/images/docs/integration/zoom/connect.png"),
    image6: require("@/assets/images/docs/integration/zoom/launch.png"),
    image7: require("@/assets/images/docs/integration/zoom/invitation.png"),
    image8: require("@/assets/images/docs/integration/zoom/share.png"),
    image9: require("@/assets/images/docs/integration/zoom/disconnect.png"),
  }),
};
</script>

<style scoped></style>
